import Vue from "vue";
import VueI18n from "vue-i18n";
Vue.use(VueI18n);
const messages = {
  es: {
    profile: {
      logout: "Cerrar Sesión",
      administrator: "Administrador",
      modifyUser: "Modificar Usuario",
      assistant: "Auxiliar",
      editInformation: "Editar Información de la Farmacia",
      email: "Correo Electrónico",
      pharmacyName: "Nombre de Farmacia",
      phone: "Telefono de Farmacia",
      address: "Dirección de Farmacia",
      admin: "Administrador de Farmacia",
      city: "Ciudad de Farmacia",
      selectCity: "Elija una ciudad",
      password: "Contraseña de Farmacia",
      location: "Localizacion de Farmacia",
      clear: "Limpiar Marcadores",
      days: "Dias",
      open: "Abierto",
      close: "Cerrado",
      sunday: "Domingo",
      monday: "Lunes",
      tuesday: "Martes",
      wednesday: "Miercoles",
      thursday: "Jueves",
      friday: "Viernes",
      saturday: "Sabado",
      chargePhoto: "Cargue una foto para su tienda",
      storePicture: "Foto de la Tienda",
      uploadedPicture: "Foto Cargada",
      connectStripe: "Registrar o connectar Stripe Connect",
      stripeCompleted: "Stripe Connect Completado",
      deliveryPreference: "Delivery de Preferencia",
      saveChanges: "Guardar Cambios",
      loading: "Cargando...",
      adminUsers: "Administrar Usuarios",
      name: "Name",
      unvinculate: "Desvincular",
      options: "Options",
      user: "User",
      password2: "Contraseña",
      delete: "Eliminar",
      userName: "Nombre de usuario",
      requiredName: "El nombre es requerido",
      lastName: "Apellido del Usuario",
      requireLastName: "El Apellido es Requerido",
      userPhone: "Telefono de Usuario",
      requiredUserPhone: "Telefono is Requerido",
      userEmail: "Correo Electronico de Usuario",
      requiredUserEmail: "El Correo Electronico es Requerido",
      userPassword: "Contraseña de Usuario",
      requiredUserPassword: "La Contraseña es Requerida",
      userRole: "Rol de Usuario",
      requiredUserRole: "Rol es Requerido",
      managementUser: "Administrador de usuairos",
      addUser: "Agregar usuario"
    },
    reports: {
      logout: "Cerrar Sesión",
      administrator: "Administrador",
      assistant: "Auxiliar",
      amount: "Cantidad",
      timeFrame: "Marco de Tiempo",
      dailySales: "Ventas Diarias",
      weeklySales: "Ventas Semanal",
      monthlySale: "Ventas Mensual",
      annualEarnings: "Reporte de Ganancias General Anual",
      month: "Mes",
      gain: "Ganancia",
      totalAnnual: "Total Annual Actual"
    },
    orders: {
      completeOrder: "Completar orden",
      logout: "Cerrar Sesión",
      searchOrder: "Buscar por Numero de Orden",
      administrator: "Administrador",
      assistant: "Auxiliar",
      orders: "Ordenes",
      notes: "Notas",
      date: "Fecha",
      pickup: "Recogida",
      delivery: "Envio",
      seeDetails: "Ver Detalles",
      deliverOrder: "Entregar Orden",
      cancelOrden: "Cancelar Orden",
      orderDetails: "Detalles de Orden",
      orderCode: "Codigo de orden",
      notPhone: "No hay Teléfono",
      item: "Articulo",
      qty: "Cant.",
      notNotes: "No hay notas disponibles",
      bagsQty: "Cantidad de Bolsas",
      shipping: "Envio",
      acceptOrden: "Acceptar Orden"
    },
    navBar: {
      products: "Productos",
      categories: "Categorias",
      orders: "Ordenes",
      profile: "Perfil",
      reports: "Reportes",
      language: "Español",
      logout: "Cerrar sesion"
    },
    barcode: {
      exit: "Salir",
      validUser: "Valide su Usuario",
      validate: "Validar",
      searchProduct: "Buscar Producto por Codigo de Barra",
      closeReader: "Cerrar Lector",
      productName: "Nombre de Producto",
      productCategory: "Categoria de Producto",
      productStatus: "Estatus de producto",
      productDescription: "Descripcion de producto"
    },
    categories: {
      searchCategory: "Buscar por categoria",
      logout: "Cerrar Sesión",
      administrator: "Administrador",
      assistant: "Auxiliar",
      categoryName: "Nombre de Categoria",
      options: "Opciones",
      delete: "Eliminar",
      update: "Actualizar",
      categories: "Categorias",
      close: "Cerrar",
      saveChanges: "Guardar Cambios",
      loading: "Cargando..."
    },
    products: {
      lowInventory: "Inventario Bajo",
      saveChanges: "Guardar Cambios",
      searchProduct: "Buscar por producto",
      logout: "Cerrar Sesión",
      administrator: "Administrador",
      assistant: "Auxiliar",
      products: "Productos",
      intoCategory: "En Category",
      searchbyBarcode: "Buscar por Codigo de barras",
      avaliableProducts: "Productos Disponibles",
      outStockProducts: "Productos Agotados",
      category: "Categoria",
      salePrice: "Precio de Venta:",
      purchasePrice: "Precio de Compra",
      available: "Disponible",
      offerPrice: "Precio de Oferta",
      quantity: "Cantidad",
      outStock: "Agotado",
      delete: "Eliminar",
      notExist: "Debe pedir mas existencia de este producto",
      close: "Cerrar",
      closeReader: "Cerrar Lector",
      search: "Buscar",
      productName: "Nombre de Producto",
      productCategory: "Categoria de Producto",
      productStatus: "Estatus de producto",
      productDescription: "Descripcion de producto",
      productQuantity: "Cantidad de producto",
      offer: "Oferta",
      addProduct: "Agregar producto",
      loading: "Cargando...",
      editProduct: "Editar producto",
      createProduct: "Crear producto",
      multipleProducts: "Multiples productos",
      oneProduct: "Un producto",
      numberFamily: "Numero de familia",
      name: "Nombre",
      suplier: "Suplidor",
      packSize: "Tamaño del paquete",
      adviceFewExist: "Aviso de Poca Existencia",
      barcode: "Codigo de barras",
      notCategories: "No tiene Categorías Disponibles",
      productDetails: "Detalles del Producto",
      notIvu: "No Aplicar IVU",
      picture: "Foto",
      choosePicture: "Seleccionar una Foto",
      uploadingPicture: "Subiendo Foto...",
      addUser: "Agregar Usuario",
      managementUser: "Administrar usuario"
    }
  },
  en: {
    profile: {
      managementUser: "User Management",
      addUser: "Add User",
      logout: "Logout",
      administrator: "Administrator",
      modifyUser: "Modify User",
      assistant: "Assistant",
      editInformation: "Edit Information of the Pharmacy",
      email: "Email",
      pharmacyName: "Pharmacy Name",
      phone: "Pharmacy Phone",
      address: "Pharmacy Address",
      admin: "Pharmacy Administrator",
      city: "Pharmacy City",
      selectCity: "Select a City",
      password: "Pharmacy Password",
      location: "Pharmacy Locate",
      clear: "Clear Markets",
      days: "Days",
      open: "Open",
      close: "Close",
      sunday: "Sunday",
      monday: "Monday",
      tuesday: "Tuesday",
      wednesday: "Wednesday",
      thursday: "Thursday",
      friday: "Friday",
      saturday: "Saturday",
      chargePhoto: "Charge a Picture for your Store",
      storePicture: "Store Picture",
      uploadedPicture: "Uploaded Picture",
      connectStripe: "Register or Connect with Stripe",
      stripeCompleted: "Stripe Connerct Complete",
      deliveryPreference: "Delivery Preference",
      saveChanges: "Save Changes",
      loading: "Loading...",
      adminUsers: "Admin Users",
      name: "Name",
      unvinculate: "Unvinculate",
      options: "Options",
      user: "User",
      password2: "Password",
      delete: "Delete",
      userName: "UserName",
      requiredName: "The UserName is required",
      lastName: "LastName",
      requireLastName: "The LastName is required",
      userPhone: "User Phone",
      requiredUserPhone: "The Phone is required",
      userEmail: "Email",
      requiredUserEmail: "The email is required",
      userPassword: "Password",
      requiredUserPassword: "The password is required",
      userRole: "Rol",
      requiredUserRole: "The Rol is required"
    },
    reports: {
      logout: "Logout",
      administrator: "Administrator",
      assistant: "Assistant",
      amount: "Amount",
      timeFrame: "Time Frame",
      dailySales: "Daily Sales",
      weeklySales: "Weekly Sales",
      monthlySale: "Monthly Sale",
      annualEarnings: "Annual General Earnings Repor",
      month: "Month",
      gain: "Gain",
      totalAnnual: "Current Total Anual"
    },
    orders: {
      completeOrder: "Complete Order",
      logout: "Logout",
      searchOrder: "Search by Orders Number",
      administrator: "Administrator",
      assistant: "Assistant",
      orders: "Orders",
      notes: "Notes",
      date: "Date",
      pickup: "PickUp",
      delivery: "Delivery",
      seeDetails: "See Details",
      deliverOrder: "Deliver Order",
      cancelOrden: "Cancel Order",
      orderDetails: "Order Details",
      orderCode: "Order Code",
      notPhone: "There is not Phone Number",
      item: "Item",
      qty: "Qty",
      notNotes: "Not Notes Avaliable",
      bagsQty: "Bags Quantity",
      shipping: "Shipping",
      acceptOrden: "Accept Order"
    },
    navBar: {
      products: "Products",
      categories: "Categories",
      orders: "Orders",
      profile: "Profile",
      reports: "Reports",
      language: "English",
      logout: "Logout"
    },
    barcode: {
      exit: "Exit",
      validUser: "Validate your User",
      validate: "Validate",
      searchProduct: "Search Product by Barcode",
      closeReader: "Close Reader",
      productName: "Product Name",
      productCategory: "Product Category",
      productStatus: "Product Status",
      productDescription: "Product Description"
    },
    categories: {
      searchCategory: "Search for a Category",
      logout: "Logout",
      administrator: "Administrator",
      assistant: "Assistant",
      categoryName: "Category Name",
      options: "Options",
      delete: "Delete",
      update: "Update",
      categories: "Categories",
      close: "Close",
      saveChanges: "Save Changes",
      loading: "Loading..."
    },
    products: {
      lowInventory: "Low Inventory",
      uploadingPicture: "Uploading Picture...",
      saveChanges: "Save Changes",
      searchProduct: "Search for a product",
      logout: "Logout",
      administrator: "Administrator",
      assistant: "Assistant",
      products: "Products",
      intoCategory: "into Category",
      searchbyBarcode: "Search by Barcode",
      avaliableProducts: "Available Products",
      outStockProducts: "Out of Stock Products",
      category: "Category",
      salePrice: "Sale Price:",
      purchasePrice: "Purchase Price:",
      offerPrice: "Precio de Oferta",
      available: "Avaliable",
      quantity: "Quantity",
      outStock: "Out of Stock",
      delete: "Delete",
      notExist: "You have to refill the existance products",
      close: "Close",
      closeReader: "Close Reader",
      search: "Search",
      productName: "Product Name",
      productCategory: "Product Category",
      productStatus: "Product Status",
      productDescription: "Product Description",
      productQuantity: "Product Quantity",
      offer: "Offer",
      addProduct: "Add Product",
      loading: "Loading...",
      editProduct: "Edit Product",
      createProduct: "Create Product",
      multipleProducts: "Multiple Products",
      oneProduct: "One Product",
      numberFamily: "Number Family",
      name: "Name",
      suplier: "Suplier",
      packSize: "Pack Size",
      adviceFewExist: "Advice of Few Existance",
      barcode: "Barcode",
      notCategories: "There is not enable sub-category",
      productDetails: "Product's Details",
      notIvu: "Do Not Apply IVU:",
      picture: "Picture",
      choosePicture: "Choose the Picure"
    }
  }
};

export const i18n = new VueI18n({
  locale: window.navigator.language.split("-")[0],
  fallbackLocale: "en",
  messages
});
