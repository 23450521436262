import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loading: false
  },
  mutations: {
    loadingComponent(state) {
      state.loading = true;
    },
    loadedComponent(state) {
      state.loading = false;
    }
  },
  actions: {
    loadingComponentAction(context) {
      context.commit("loadingComponent");
    },
    loadedComponentAction(context) {
      context.commit("loadedComponent");
    }
  },
  modules: {},
  getters: {
    getComponentLoadingState(state) {
      return state.loading;
    }
  }
});
