import Vue from "vue";
import VueRouter from "vue-router";
import parse from "parse";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    component: () => import("../views/Login")
  },
  {
    path: "/forgotPassword",
    name: "ForgotPassword",
    component: () => import("../views/ForgotPassword.vue")
  },
  {
    path: "/orders",
    name: "Orders",
    component: () => import("../views/panel/Orders.vue"),
    beforeEnter: (to, from, next) => {
      if (parse.User.current().get("isAdmin")) {
        next();
      } else {
        next(false);
      }
    }
  },
  {
    path: "/barcode",
    name: "Barcode",
    component: () => import("../views/panel/Barcode.vue"),
    beforeEnter: (to, from, next) => {
      if (parse.User.current().get("isAdmin")) {
        next();
      } else {
        next(false);
      }
    }
  },
  {
    path: "/products",
    name: "Products",
    component: () => import("../views/panel/Products.vue"),
    beforeEnter: (to, from, next) => {
      if (parse.User.current().get("isAdmin")) {
        next();
      } else {
        next(false);
      }
    }
  },
  {
    path: "/categories",
    name: "Categories",
    component: () => import("../views/panel/Categories.vue"),
    beforeEnter: (to, from, next) => {
      if (parse.User.current().get("isAdmin")) {
        next();
      } else {
        next(false);
      }
    }
  },
  {
    path: "/profile",
    name: "Profile",
    component: () => import("../views/panel/Profile.vue"),
    beforeEnter: (to, from, next) => {
      if (parse.User.current().get("isAdmin")) {
        next();
      } else {
        next(false);
      }
    }
  },
  {
    path: "/reports",
    name: "Reports",
    component: () => import("../views/panel/Reports.vue"),
    beforeEnter: (to, from, next) => {
      if (parse.User.current().get("isAdmin")) {
        next();
      } else {
        next(false);
      }
    }
  },
  {
    path: "/farmacies",
    name: "Farmacies",
    component: () => import("../views/admin/Farmacies.vue"),
    beforeEnter: (to, from, next) => {
      if (parse.User.current().get("superAdmin")) {
        next();
      } else {
        next(false);
      }
    }
  },
  {
    path: "/farmacies/:id",
    name: "FarmacyDetails",
    component: () => import("../views/admin/FarmacyDetails.vue"),
    beforeEnter: (to, from, next) => {
      if (parse.User.current().get("superAdmin")) {
        next();
      } else {
        next(false);
      }
    }
  },
  {
    path: "/ordersAdmin",
    name: "OrdersAdmin",
    component: () => import("../views/admin/OrdersAdmin.vue"),
    beforeEnter: (to, from, next) => {
      if (parse.User.current().get("superAdmin")) {
        next();
      } else {
        next(false);
      }
    }
  },
  {
    path: "/drivers",
    name: "Drivers",
    component: () => import("../views/admin/Drivers.vue"),
    beforeEnter: (to, from, next) => {
      if (parse.User.current().get("superAdmin")) {
        next();
      } else {
        next(false);
      }
    }
  },
  {
    path: "/driversProfile/:id",
    name: "DriversProfile",
    component: () => import("../views/admin/DriversProfile.vue"),
    beforeEnter: (to, from, next) => {
      if (parse.User.current().get("superAdmin")) {
        next();
      } else {
        next(false);
      }
    }
  },
  {
    path: "/ads",
    name: "Ads",
    component: () => import("../views/admin/Ads.vue"),
    beforeEnter: (to, from, next) => {
      if (parse.User.current().get("superAdmin")) {
        next();
      } else {
        next(false);
      }
    }
  },
  {
    path: "/reportsAdmin",
    name: "ReportsAdmin",
    component: () => import("../views/admin/ReportsAdmin.vue"),
    beforeEnter: (to, from, next) => {
      if (parse.User.current().get("superAdmin")) {
        next();
      } else {
        next(false);
      }
    }
  },
  {
    path: "/farmacyInventory/:id",
    name: "FarmacyInventory",
    component: () => import("../views/admin/FarmacyInventory.vue"),
    beforeEnter: (to, from, next) => {
      if (parse.User.current().get("superAdmin")) {
        next();
      } else {
        next(false);
      }
    }
  },
  {
    path: "/passwordRestore/:store",
    name: "PasswordRestore",
    component: () => import("../views/admin/PasswordRestore.vue")
  },
  {
    path: "/stripeValidate",
    name: "StripeValidate",
    component: () => import("../views/panel/StripeValidated.vue")
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/Register.vue")
  },
  {
    path: "*",
    component: () => import("../views/Not-Found.vue")
  }
];

const router = new VueRouter({
  routes
});
router.beforeEach((to, from, next) => {
  if (
    !parse.User.current() &&
    to.path != "/" &&
    to.path === "/passwordRestore/:store"
  ) {
    next(false);
  } else {
    next();
  }
});

export default router;
