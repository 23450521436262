<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
    <Loading/>
  </v-app>
</template>
<script>
import Loading from './components/Loading'
export default {
  components:{
    Loading
  }
}
</script>
