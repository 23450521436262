import Vue from "vue";
import App from "./App.vue";
//import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "./plugins/init_parse";
import { i18n } from "@/plugins/i18n";
import vuetify from "./plugins/vuetify";
import VueSweetalert2 from "./plugins/VueSeetalert2";

Vue.config.productionTip = false;

new Vue({
  router,
  i18n,
  store,
  vuetify,
  VueSweetalert2,
  render: h => h(App)
}).$mount("#app");
