<template>
    <div>
     <div v-if="this.$store.getters.getComponentLoadingState" class="loader">
        <div
          :class="
            this.$store.getters.getComponentLoadingState ? 'loader-show' : ''
          "
          class="loader-container "
        >
          <RotateSquare2 />
          <p class="loader-text">Loading...</p>
        </div>
      </div>
    </div>
</template>
<script>
import { RotateSquare2 } from "vue-loading-spinner";
export default {
    name:"Loading",
    components:{
        RotateSquare2
    }
}
</script>
<style scoped>
.loader-container {
  background: #fff;
  width: 400px;
  height: 300px;
  transition: 0.3s all ease;
  transform: scale(0);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.loader-show {
  transform: scale(1) !important;
}
.loader {
  width: 100%;
  height: 100%;
  left: 0;
  position: absolute;
  z-index: 999999;
  overflow: hidden;
  display: flex;
  top: 0;
  background: rgba(0, 0, 0, 0.4);
  justify-content: center;
  align-items: center;
}
.loader-text {
  font-family: "segoe ui";
  font-style: italic;
  font-size: 20px;
}
</style>